'use strict'

###*
 # @ngdoc object
 # @name users.controller:DataTableDetailModalCtrl

 # @description

###
class DataTableDetailModalCtrl
  ### @ngInject ###
  constructor: (
    entityManager
    UserPermissions
    $log
    $mdDialog
    FormDialog
    ConfirmBox
    $q
    $filter
    $sce
    $rootScope
    $state
    id
    MundoHelper
    _
    mundoAuditPermission
    MundoActions
  ) ->
    init = () =>

      @id = id
      @entityManager = entityManager

      @row = {}

      @schema = entityManager.getListSchema()
      @schemaLinks = if entityManager.getSchemaLinks? then entityManager.getSchemaLinks() else []
      @detailEditActions = entityManager.getDetailEditActions()
      @extraActions = entityManager.getExtraActions()
      @templateOptions = if entityManager.templateOptions then entityManager.templateOptions else {}

      @openDetailDialog = openDetailDialog
      @openExtraAction = openExtraAction
      @openDetailEditDialog = openDetailEditDialog
      @openEditDialog = openEditDialog
      @openAuditDialog = openAuditDialog
      @cancel = ->
        $mdDialog.cancel()

      @helper = new MundoHelper(entityManager)

      @isEditable = @helper.checkAction('update')
      @isDeletable = @helper.checkAction('delete')
      @isCreatable = @helper.checkAction('create')
      @isPrintable = @helper.checkAction('print')
      @isRefreshable = @helper.checkAction('refresh')
      @isSearchable = @helper.checkAction('search') && entityManager.conf.searchFields.length > 0
      @isAuditable = @helper.checkAction('audit')

      @hasEditPermission = @helper.hasPermission
      @hasAuditPermission = -> @helper.hasPermission(mundoAuditPermission)
      @hasPermission = -> @helper.hasPermission

      reload()

    reload = =>
      entityManager.one(@id)
      .then (data) =>
        @row = data
        @dialogTitle = @helper.getValue(data,'label')

    openDetailEditDialog = (item, key) =>
      if @hasPermission()
        action = @detailEditActions[key][0]
        $log.debug 'action', action
        action.entityManager.setParentId(@row.id)
        FormDialog.showFormDialog(action.title, action.entityManager, item)
        .then  ->
          reload()

    openExtraAction = (action) =>
      action.action(@row.id,@row)

    openEditDialog = () =>
      if @hasPermission()
        FormDialog.showFormDialog(@dialogTitle, @entityManager, @row)
        .then () ->
          reload()

    openAuditDialog = () =>
      if not @hasAuditPermission()
        return

      object_id = @entityManager.getAuditObjectId @row

      MundoActions.execute 'audit_logs',
        data:
          object_id: object_id

    openDetailDialog = (id,type) =>

      detailEntityManager = entityManager

      if(type?)
        detailEntityManager = @helper.getEntityManager(type)

      if @hasPermission()
        $mdDialog.show
          templateUrl: detailEntityManager.getDetailModalTemplateUrl()
          controller: 'DataTableDetailModalCtrl'
          controllerAs: 'detailCtrl'
          skipHide: true
          locals:
            id: id
            entityManager: detailEntityManager

    init()


angular
  .module('mundoComponents')
  .controller 'DataTableDetailModalCtrl', DataTableDetailModalCtrl
